import { Alert, Anchor, Button, Center, Checkbox, Container, Divider, Group, Radio, Stack, Text, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { useLocalStorage } from "@mantine/hooks";
import { IconCloud, IconCloudDollar, IconPaperclip } from "@tabler/icons-react";
import { AppContext } from "components/AppContext/AppContext";
import { queryClient } from "components/AppProviders";
import { SelectableItem } from "components/SelectableItem/SelectableItem";
import { useCreateEnvironment } from "hooks/queries/environments";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "utils/toast";
import * as Yup from 'yup'

export default function EnvironmentSignUp() {
    const { setValues, ...form } = useForm<EnvironmentRequest & { termsAccepted: boolean; subscribeToProductUpdates: boolean }>({
        initialValues: {
            city: 'City',
            addressLine1: '555 Example Rd',
            addressLine2: '',
            state: 'WA',
            country: 'USA',
            zip: '98000',
            phone: '+14255555555',
            email: 'admin@example.com',
            name: '',
            firstName: 'Trial',
            middleName: '',
            lastName: 'User',
            privacyStatement: 'https://privacy-statement.example.com',
            termsOfUse: 'https://terms-of-use.example.com',
            environmentType: 'Trial',
            termsAccepted: false,
            subscribeToProductUpdates: false,
        },
        validate: yupResolver(schema),
        validateInputOnChange: true
    });
    const { mutate: createEnvironment, isPending: isPending } = useCreateEnvironment();
    const [_, setEnvironment] = useLocalStorage({ key: 'environment' });
    const navigate = useNavigate();
    const appContext = useContext(AppContext);
    const [errorMessage, setErrorMessage] = useState('');
    const [allowedEnvironmentsValue, setAllowedEnviornmentsValue] = useState(0);

    useEffect(() => {
        const key = form.values.environmentType === 'Trial' ? 'TrialAllowedEnvironments' : 'PaidAllowedEnvironments';
        const allowedEnvironmentsCapability = appContext.currentUser?.capabilities.find(c => c.id === key);
        const allowedEnvironmentsValue = allowedEnvironmentsCapability?.value as number ?? 0;
        setAllowedEnviornmentsValue(allowedEnvironmentsValue);
    }, [form.values.environmentType, appContext]);

    const handleFormSubmit = (environment: EnvironmentRequest) => {
        setErrorMessage('');
        createEnvironment(environment, {
            onError: (error) => {
                const problem = error as ProblemDetails;
                setErrorMessage(problem.errors.request[0]);
            },
            onSuccess: (data) => {
                setEnvironment(data.id);
                queryClient.invalidateQueries({ queryKey: ['environments'] });
                queryClient.invalidateQueries({ queryKey: ['users'] });

                navigate('/getting-started');
            }
        });
    };

    return <>
        <form onSubmit={form.onSubmit(handleFormSubmit)}>
            <Center>
                <Container w='640px'>
                    <h1>
                        <Text
                            gradient={{ from: 'indigo', to: 'purple', deg: 45 }}
                            variant="gradient">
                            Create a New Environment
                        </Text>
                    </h1>

                    <Stack mt='md'>
                        <TextInput
                            label='Environment Name'
                            description='Choose a name for your new environment'
                            placeholder="Default Environment"
                            withAsterisk
                            disabled={isPending}
                            {...form.getInputProps('name')} />

                        <p>Choose your environment type</p>

                        <SelectableItem
                            checked={form.values.environmentType === 'Trial'}
                            title="Trial"
                            description="Create an environment for evaluation/non-production use"
                            icon={<IconCloud />}
                            onChange={(value) => value ? form.setFieldValue('environmentType', 'Trial') : form.setFieldValue('environmentType', 'Paid')} />

                        <SelectableItem
                            checked={form.values.environmentType === 'Paid'}
                            title="Paid"
                            description="Create a production enviornment that has full functionality and support. Note: billing information is required to continue."
                            icon={<IconCloudDollar />}
                            onChange={(value) => value ? form.setFieldValue('environmentType', 'Paid') : form.setFieldValue('environmentType', 'Trial')} />


                        <Divider />
                        <Checkbox
                            label={<Text>I agree to Consentify <Anchor href="https://www.consentify.io/terms-of-use" target="_blank">Term of Use</Anchor> and <Anchor href="https://www.consentify.io/privacy-statement" target="_blank">Privacy Statement</Anchor>.</Text>}
                            {...form.getInputProps('termsAccepted')}
                        />
                        <Checkbox
                            label={<Text>I want to receive product updates, news and offers from Consentify.</Text>}
                            {...form.getInputProps('subscribeToProductUpdates')}
                        />
                    </Stack>

                    <Stack mt='md'>
                        <Text c='red'>
                            {errorMessage}
                        </Text>

                        {allowedEnvironmentsValue > 1 && (
                            <p>
                                The current user is entitled to create {allowedEnvironmentsValue} environment(s).
                            </p>
                        )}

                        {(!allowedEnvironmentsValue || allowedEnvironmentsValue < 1) && (
                            <Alert color='red'>
                                The current user cannot create new environment because the user has hit the maximum number of environments
                                that can be created. Please purchase more environment licenses to create new environments.
                            </Alert>
                        )}
                        {allowedEnvironmentsValue > 1 && (<Button
                            variant='gradient'
                            gradient={{ from: 'indigo', to: 'purple', deg: 45 }}
                            type='submit'
                            fullWidth
                            disabled={!appContext.hasPermission('Environment.Create') || !form.isValid()}
                            loading={isPending}>Create New Environment</Button>
                        )}
                    </Stack>
                </Container>
            </Center>
        </form>
    </>
}

const schema = Yup.object().shape({
    name: Yup.string()
        .min(8, 'Environment name should have at least 8 characters')
        .required('Environment name is required')
        .default('Default Environment'),
    environmentType: Yup.string()
        .required('Environment type is required')
        .equals(['Trial', 'Paid'], 'Currently you can only create trial environments'),
    termsAccepted: Yup.boolean()
        .required('You must accept the terms of use and privacy statement')
        .equals([true], 'Please accept the terms of use and privacy statement'),
    subscribeToProductUpdates: Yup.boolean()
        .optional(),
});