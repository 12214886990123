import {
    type AccountInfo,
    type IPublicClientApplication
} from '@azure/msal-browser';
import { InteractionRequiredAuthError } from '@azure/msal-common';
import qs, { type IStringifyOptions } from 'qs';

import { isDevEnv } from './env';

export default class HttpClient {
    readonly baseUri: string;
    readonly options: HttpClientOptions;

    constructor(baseUri: string, options?: HttpClientOptions) {
        this.baseUri = baseUri;
        this.options = options ?? {
            headers: {
                Accept: 'application/json, text/plain'
            }
        };
    }

    private getRequestBody(body: unknown): { body: RequestInit['body'] } {
        if (body instanceof FormData) {
            return { body };
        } else if (typeof body === 'object' && body !== null) {
            return { body: JSON.stringify(body) };
        }

        return { body: body as RequestInit['body'] };
    }

    async request<T>(conf: RequestConf): Promise<T> {
        const { apiPath, data, method, query } = conf;

        let queryOptions, queryParams;

        if (query && 'options' in (query as QueryConf)) {
            queryOptions = (query as QueryConf).options;
            queryParams = (query as QueryConf).params;
        } else {
            queryParams = query;
        }

        const search = query
            ? `?${qs.stringify(queryParams, queryOptions as IStringifyOptions)}`
            : '';

        const url = `${this.baseUri}/${apiPath}${search}`;

        const reqestInit = {
            ...this.options,
            method,
            ...(data !== undefined && this.getRequestBody(data)),
            ...({
                headers: new Headers({
                    ...this.options.headers,
                    ...conf.headers,
                    ...(!(data instanceof FormData) && { 'Content-Type': 'application/json;charset=UTF-8' })
                })
            })
        };

        const response = await fetch(url, reqestInit);
        const text = await response.text();

        if (response.ok) {
            try {
                return JSON.parse(text) as T;
            } catch {
                return text as T;
            }
        }

        try {
            const details = JSON.parse(text) as ProblemDetails;
            return Promise.reject(details);
        }
        catch {
            return Promise.reject({ title: 'There was an error while processing the request.', status: response.status });
        }
    }

    async delete<T>(conf: Omit<RequestConf, 'method' | 'data'>): Promise<T> {
        return await this.request({
            ...conf,
            method: 'DELETE'
        });
    }

    async get<T>(
        conf: Omit<RequestConf, 'method' | 'data'>
    ): Promise<T> {
        return await this.request({
            ...conf,
            method: 'GET'
        });
    }

    async patch<T>(conf: UpdateRequestConf): Promise<T> {
        return await this.request({
            ...conf,
            method: 'PATCH'
        });
    }

    async post<T>(conf: UpdateRequestConf): Promise<T> {
        return await this.request({
            ...conf,
            method: 'POST'
        });
    }

    async put<T>(conf: UpdateRequestConf): Promise<T> {
        return await this.request({
            ...conf,
            method: 'PUT'
        });
    }
}

export class ConsentifyClient extends HttpClient {
    private accessToken = '';
    private readonly account: AccountInfo | null;
    private readonly instance: IPublicClientApplication;
    private readonly scopes = ['api://consentify-ucp/User.Read'];
    private readonly environment: string;

    static readonly consentifyApiBaseUri = isDevEnv
        ? 'https://localhost:5001'
        : 'https://api.consentify.io';

    constructor(msal: IPublicClientApplication, environment: string) {
        super(ConsentifyClient.consentifyApiBaseUri);

        this.account = msal.getActiveAccount();
        this.instance = msal;
        this.environment = environment;
    }

    private async acquireToken(): Promise<string> {
        if (this.instance != null && this.account != null) {
            try {
                const accessTokenResponse = await this.instance.acquireTokenSilent({
                    account: this.account,
                    scopes: this.scopes
                });

                return accessTokenResponse.accessToken;
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    const accessTokenResponse = await this.instance.acquireTokenPopup({
                        scopes: this.scopes
                    });

                    return accessTokenResponse.accessToken;
                }

                return '';
            }
        }

        return '';
    }

    async request<T>(conf: RequestConf): Promise<T> {
        if (!this.accessToken) {
            this.accessToken = await this.acquireToken();
        }

        const accessToken = this.accessToken;

        const result = await super.request({
            ...conf,
            headers: {
                authorization: `bearer ${accessToken}`,
                'x-environment-id': this.environment
            }
        });

        return result as T;
    }
}

export type HttpClientOptions = Pick<
    RequestInit,
    'credentials' | 'headers' | 'mode'
>;

interface RequestConf {
    apiPath: string
    data?: unknown
    headers?: HttpClientOptions['headers']
    method: 'DELETE' | 'GET' | 'POST' | 'PATCH' | 'PUT'
    query?: QueryConf | QueryConf['params'];
}

interface UpdateRequestConf
    extends Required<Pick<RequestConf, 'apiPath'>> {
    data?: RequestConf['data'];
    headers?: RequestConf['headers'];
}

interface QueryConf {
    options: IStringifyOptions;
    params: unknown;
}
