export const isDevEnv = process.env.NODE_ENV === 'development';

export const isProdEnv = process.env.NODE_ENV === 'production';

export const warnInDevEnv = (message: any) => {
    if (isDevEnv) {
        // eslint-disable-next-line no-console
        console.warn(message);
    }
};

export const logInDevEnv = (message: any) => {
    if (isDevEnv) {
        // eslint-disable-next-line no-console
        console.log(message);
    }
};
