import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { Providers } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';
import { QueryClientProvider } from '@tanstack/react-query';
import React from 'react';

import { msalConfig } from './authConfig';
import queryClient from './queryClient';

const msal = new PublicClientApplication(msalConfig);
Providers.globalProvider = new Msal2Provider({ publicClientApplication: msal as PublicClientApplication, scopes: ['User.Read.All', 'Directory.Read.All', 'Presence.Read.All'] });

const AppProviders: React.FC<{ children: React.ReactElement }> = ({ children }) => {
    return (
        <MsalProvider instance={msal}>
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
        </MsalProvider>
    );
};

export default AppProviders;
