import { UnstyledButton, Checkbox, Text, createStyles } from '@mantine/core';
import { useUncontrolled } from '@mantine/hooks';
import { ReactNode } from 'react';

interface SelectableItemProps {
    checked?: boolean;
    defaultChecked?: boolean;
    onChange?(checked: boolean): void;
    title: string;
    description: string;
    icon: ReactNode;
}

const useStyles = createStyles((theme) => ({
    button: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        borderRadius: theme.radius.sm,
        padding: theme.spacing.sm,
        border: `1px solid`,
        borderColor: theme.colorScheme === 'light' ? theme.colors.gray[3] : theme.colors.dark[8],
        transition: 'background-color 150ms ease, border-color 150ms ease;',
        backgroundColor: theme.colorScheme === 'light' ? theme.white : theme.colors.dark[8],

        '&[data-checked]': {
            borderColor: theme.colors.blue[6],
            backgroundColor: theme.colorScheme === 'light' ? theme.colors.gray[3] : theme.colors.dark[6]
        }
    },
    body: {
        flex: 1,
        marginLeft: theme.spacing.md
    }
}));

export function SelectableItem({
    checked,
    defaultChecked,
    onChange,
    title,
    description,
    className,
    icon,
    ...others
}: SelectableItemProps & Omit<React.ComponentPropsWithoutRef<'button'>, keyof SelectableItemProps>) {
    const [value, handleChange] = useUncontrolled({
        value: checked,
        defaultValue: defaultChecked,
        finalValue: false,
        onChange,
    });

    const { classes } = useStyles();

    return (
        <UnstyledButton
            {...others}
            onClick={() => handleChange(!value)}
            data-checked={value || undefined}
            className={classes.button}
        >
            {icon}

            <div className={classes.body}>
                <Text fw={500} size="sm" lh={1}>
                    {title}
                </Text>
                <Text c="dimmed" size="xs" mt="sm" lh={1} mb={5}>
                    {description}
                </Text>
            </div>

        </UnstyledButton>
    );
}