import { ConsentifyClient } from 'utils/httpClient';

export class UsersApi extends ConsentifyClient {
    createUser = (update: UserRequest) => {
        return this.post<UserResponse>({
            apiPath: 'users',
            data: update
        });
    };

    deleteUser = (id: string) => {
        return this.delete({
            apiPath: `users/${id}`
        });
    };

    getUsers = () => {
        return this.get<UserResponse[]>({
            apiPath: 'users'
        });
    };

    getUser = (id: string) => {
        return this.get<UserResponse>({
            apiPath: `users/${id}`
        });
    };

    getCurrentUser = () => {
        return this.get<UserResponse>({
            apiPath: `users/me`
        });
    };

    updateUser = (update: UserResponse) => {
        return this.patch<UserResponse>({
            apiPath: `users/${update.userId}`,
            data: update
        });
    };
}
