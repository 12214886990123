import DashboardPage from './BashboardPage';
import EnvironmentSignUp from './EnvironmentSignUp';
import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { AppContext } from 'components/AppContext/AppContext';

export default function HomePage() {
    const appContext = useContext(AppContext);
    const environments = appContext.environments;

    if (environments.length === 0) {
        return <EnvironmentSignUp />
    }

    return (
        <Routes>
            <Route
                element={<DashboardPage />}
                path="*"
                index
            />
            <Route
                element={<EnvironmentSignUp />}
                path="create-environment/*"
            />
            
        </Routes>);
}
