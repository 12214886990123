import {
    Alert,
    AppShell,
    Badge,
    Box,
    Button,
    Center,
    createStyles,
    Divider,
    Flex,
    Group,
    Header,
    Indicator,
    LoadingOverlay,
    Modal,
    Navbar,
    NavLink,
    ScrollArea,
    Select,
    Text
} from '@mantine/core';
import { useDisclosure, useLocalStorage } from '@mantine/hooks';
import { Notifications } from '@mantine/notifications';
import {
    type ColorScheme,
    ColorSchemeProvider,
    MantineProvider
} from '@mantine/styles';
import { Login, ThemeToggle } from '@microsoft/mgt-react';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';

import Logo from 'images/logo.png';

import { AppNavAdditionalLinks, AppNavMainLinks } from './AppNavLinks';
import { IconHome } from '@tabler/icons-react';
import { useGetEnvironments } from 'hooks/queries/environments';
import { queryClient } from 'components/AppProviders';
import { useContext, useEffect, useRef, useState } from 'react';
import { useGetCurrentUser } from 'hooks/queries/users';
import { AppContext } from 'components/AppContext/AppContext';
import HomePage from 'pages/getting-started/HomePage';
import { PurgeEnvironment } from '../Actions/PurgeEnviornment';

export default function AppLayout() {
    const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
        key: 'consentify-color-scheme',
        defaultValue: 'light'
    });

    const [environment, setEnvironment] = useLocalStorage({ key: 'environment', getInitialValueInEffect: false });
    const { data: environments, isPending: isEnvironmentsPending } = useGetEnvironments();
    const { data: user } = useGetCurrentUser();
    const [currentEnvironment, setCurrentEnvironment] = useState<EnvironmentResponse>();
    const [currentUser, setCurrentUser] = useState<UserResponse>();

    useEffect(() => {
        if (!environments) {
            return;
        }

        if (environments.length !== 0) {
            let matchedEnvironment = environments[0];

            if (environment) {
                matchedEnvironment = environments.find(e => e.id === environment) ?? matchedEnvironment;
            }

            setEnvironment(matchedEnvironment.id);
            setCurrentEnvironment(matchedEnvironment);
        } else {
            setEnvironment('');
        }

        if (user) {
            setCurrentUser(user);
        }
    }, [environment, environments, user]);

    if (isEnvironmentsPending) {
        return (<ColorSchemeProvider
            colorScheme={colorScheme}
            toggleColorScheme={(value) => {
                setColorScheme(value ?? 'dark');
            }}
        >
            <ThemeToggle darkModeActive={colorScheme === 'dark'} />
            <MantineProvider
                theme={{ colorScheme }}
                withGlobalStyles
                withNormalizeCSS>
                <LoadingOverlay visible={isEnvironmentsPending} />
            </MantineProvider>
        </ColorSchemeProvider>);
    }

    return (
        <AppContext.Provider value={{
            currentEnvironment: currentEnvironment,
            currentUser: currentUser,
            environments: environments || [],
            selectedEnvironment: environment,
            hasPermission: (permission) => !!currentUser?.permissions.find(p => p === permission)
        }}>
            <ColorSchemeProvider
                colorScheme={colorScheme}
                toggleColorScheme={(value) => {
                    setColorScheme(value ?? 'dark');
                }}
            >
                <ThemeToggle darkModeActive={colorScheme === 'dark'} />
                <MantineProvider
                    theme={{ colorScheme }}
                    withGlobalStyles
                    withNormalizeCSS
                >
                    <Notifications limit={5}
                        position="top-right" />
                    <AppShell
                        header={<AppHeader onSelectedEnvironmentChange={setEnvironment} />}
                        navbar={<AppNavbar />
                        }
                        padding="md"
                        styles={({ colorScheme, colors }) => ({
                            main: {
                                backgroundColor: (colorScheme === 'dark')
                                    ? colors.dark[8]
                                    : colors.gray[0]
                            }
                        })}
                    >
                        <AppBody />
                    </AppShell>
                </MantineProvider>
            </ColorSchemeProvider>
        </AppContext.Provider>
    );
}

const AppNavbar = () => {
    const appContext = useContext(AppContext);
    const environment = appContext.currentEnvironment;
    if (environment) {
        return (
            <Navbar p="xs"
                width={{ base: 300 }}>
                <Navbar.Section component={ScrollArea}
                    grow
                    mt="xl">
                    <AppNavMainLinks />
                </Navbar.Section>
                <Navbar.Section>
                    <Divider />
                    <AppNavAdditionalLinks />
                </Navbar.Section>
            </Navbar>
        );
    }

    return (
        <Navbar p="xs"
            width={{ base: 300 }}>
            <Navbar.Section component={ScrollArea}
                grow
                mt="xl">
                <NavLink
                    active
                    component={Link}
                    icon={<IconHome size={16} />}
                    label='Getting Started'
                    to='/getting-started' />
            </Navbar.Section>
        </Navbar>
    );
};

const AppHeader: React.FC<{ onSelectedEnvironmentChange: (val: string | ((prevState: string) => string)) => void }> = (prop) => {
    const { classes } = useStyles();
    const navigate = useNavigate();
    const appContext = useContext(AppContext);
    const environments = appContext.environments;
    const environment = appContext.selectedEnvironment;

    const onEnvironmentChange = (value: string) => {
        prop.onSelectedEnvironmentChange(value);
        queryClient.invalidateQueries({ queryKey: ['environments'] });
        navigate('/getting-started', { replace: true });
    };

    return (
        <Header height={60}
            px="xs">
            <Group align="center"
                position="apart">
                <Box>
                    <Link className={classes.navBrand}
                        to="/">
                        <Group spacing=".25rem">
                            <img alt="Logo"
                                height="48"
                                src={Logo}
                                width="48" />
                            <Group spacing=".25rem">
                                <Text>Consentify Business Portal</Text>
                                <Badge size='xs'
                                    tt="uppercase">Alpha</Badge>
                            </Group>
                        </Group>
                    </Link>
                </Box>
                <Box>
                </Box>
                <Flex align="center"
                    gap=".5rem"
                >
                    <Select
                        searchable
                        value={environment}
                        onChange={onEnvironmentChange}
                        data={environments?.map(e => ({ label: e.name, value: e.id })) || []}
                        creatable
                        shouldCreate={(query, data) => true}
                        getCreateLabel={(query) => `+ Create`}
                        onCreate={(query) => {
                            navigate('/getting-started/create-environment', { replace: true });

                            return null;
                        }} />
                    <Link to="/background-jobs">
                        <Indicator className={classes.indicator}>
                            <IoMdNotificationsOutline size="1.25rem" />
                        </Indicator>
                    </Link>
                    <Login showPresence={false} />
                </Flex>
            </Group>
        </Header>
    );
};

const AppBody = () => {
    const appContext = useContext(AppContext);
    const currentEnvironment = appContext.currentEnvironment;
    const [opened, { open, close }] = useDisclosure(false);
    const scrollViewport = useRef<HTMLDivElement>(null);

    if (!currentEnvironment) {
        return <HomePage />
    }

    if (currentEnvironment.daysInExpiring !== undefined) {
        const daysInExpiring = currentEnvironment.daysInExpiring;

        if (daysInExpiring > 0) {
            return <>
                <Flex
                    direction='column'
                    h='100%'>
                    <Alert
                        color='yellow'
                        mb='md'>
                        <Group position='apart'>
                            <div>
                                The environment <b>{currentEnvironment.name}</b> will expire in {currentEnvironment.daysInExpiring} day(s).
                            </div>
                            <Button variant='outline' onClick={open} color='red' disabled={!appContext.hasPermission('Environment.Delete')}>Purge Environment</Button>
                            <Modal opened={opened} onClose={close} title='Confirm Operation'>
                                <PurgeEnvironment environment={currentEnvironment} onSuccess={() => { close(); location.reload(); }} />
                            </Modal>
                        </Group>
                    </Alert>
                    <ScrollArea
                        style={{ flexGrow: 1 }}
                        viewportRef={scrollViewport}>
                        <Outlet />
                    </ScrollArea>
                </Flex>
            </>
        }

        if (daysInExpiring && daysInExpiring <= 0) {
            return <>
                <Flex
                    direction='column'
                    h='100%'>
                    <Alert
                        color='red'
                        mb='md'>
                        <Group position='apart'>
                            <div>
                                The environment <b>{currentEnvironment.name}</b> has expired. All data in this environment is read only.
                            </div>
                            <Button variant='outline' onClick={open} color='red' disabled={!appContext.hasPermission('Environment.Delete')}>Purge Environment</Button>
                            <Modal opened={opened} onClose={close} title='Confirm Operation'>
                                <PurgeEnvironment environment={currentEnvironment} onSuccess={() => { close(); location.reload(); }} />
                            </Modal>
                        </Group>
                    </Alert>
                    <ScrollArea
                        style={{ flexGrow: 1 }}
                        viewportRef={scrollViewport}>
                        <Outlet />
                    </ScrollArea>
                </Flex>
            </>
        }
    }

    return <Outlet />
}

const useStyles = createStyles(() => ({
    navBrand: {
        color: 'inherit',
        textDecoration: 'none'
    },
    indicator: {
        display: 'flex',
        alignItems: 'center'
    }
}));