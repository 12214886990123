import { Alert, Button, Group, TextInput } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { queryClient } from "components/AppProviders";
import { usePurgeEnvironment } from "hooks/queries/environments";
import React from "react";
import { useNavigate } from "react-router-dom";
import toast from "utils/toast";
import * as Yup from 'yup'

export const PurgeEnvironment: React.FC<PurgeEnvironmentProps> = ({ ...prop }) => {
    const environment = prop.environment;
    const { mutate: purgeEnvironment, isPending: isPending } = usePurgeEnvironment();
    const navigate = useNavigate();

    const schema = Yup.object().shape({
        name: Yup.string()
            .required('Environment name is required')
            .equals([environment.name]),
        id: Yup.string()
            .required('Environment ID is required')
    });

    const { ...form } = useForm<PurgeEnvironmentRequest>({ initialValues: { id: environment.id }, validate: yupResolver(schema), validateInputOnChange: true });
    const handleFormSubmit = (environment: PurgeEnvironmentRequest) => {
        purgeEnvironment(environment.id, {
            onError: () => {
                toast.error('Failed to purge environment, please try again later.');
            },
            onSuccess: (data) => {
                queryClient.invalidateQueries({ queryKey: ['environments'] });
                prop.onSuccess();
            }
        });
    };

    return <>
        <form onSubmit={form.onSubmit(handleFormSubmit)}>
            <p>
                You are going to perform an operation that cannot be reverted, please type the identifier below and confirm the operation.
            </p>
            <input type="hidden"
                value={form.values.id}
            />
            <TextInput
                autoFocus
                label={`Please enter the environment name: ${environment.name}`}
                {...form.getInputProps('name')}
            />
            <Alert color='red' mt='md'>
                Warning: If you confirm to delete this environment, you will not be able to restore it in the future, even if you create an environment
                with the same name.
            </Alert>
            <p>
                All data related to this enviornment such as purposes, subscriptions, contact profiles, contact points, consent collection forms, will be removed.
            </p>

            <Group position="right">
                <Button
                    loading={isPending}
                    disabled={!form.isValid()}
                    color="red"
                    type="submit">Purge Environment</Button>
            </Group>
        </form>
    </>;
};

declare interface PurgeEnvironmentProps {
    environment: EnvironmentResponse;
    onSuccess: () => void;
};

declare interface PurgeEnvironmentRequest {
    id: string;
    name?: string;
}