import { useMsal } from '@azure/msal-react';
import { useLocalStorage } from '@mantine/hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { UsersApi } from 'services/users';

export const USERS_QUERY_KEY = ['users'];

export function useUsersApi () {
    const {
        instance
    } = useMsal();
    const [environment] = useLocalStorage({ key: 'environment', getInitialValueInEffect: false });
    
    return useMemo(
        () => new UsersApi(instance, environment),
        [instance, environment]
    );
}

export function useCreateUser () {
    const api = useUsersApi();

    return useMutation({
        mutationFn: api.createUser,
        mutationKey: USERS_QUERY_KEY
    });
}
export const useDeleteUser = () => {
    const api = useUsersApi();

    return useMutation({ mutationFn: api.deleteUser });
};

export function useGetUsers () {
    const api = useUsersApi();

    return useQuery({
        queryFn: api.getUsers,
        queryKey: USERS_QUERY_KEY
    });
}

export function useGetUser (id: string) {
    const api = useUsersApi();

    return useQuery({
        queryFn: () => api.getUser(id),
        queryKey: [...USERS_QUERY_KEY, id]
    });
}

export function useGetCurrentUser () {
    const api = useUsersApi();

    return useQuery({
        queryFn: () => api.getCurrentUser(),
        queryKey: [...USERS_QUERY_KEY, 'me']
    });
}

export function useUpdateUser () {
    const api = useUsersApi();

    return useMutation({
        mutationFn: api.updateUser
    });
}
