import { InteractionStatus } from '@azure/msal-browser';
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useIsAuthenticated,
    useMsal
} from '@azure/msal-react';
import { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AppLayout from 'components/AppLayout';
import { loginRequest } from 'components/AppProviders';
import UnauthenticatedPage from 'components/UnauthenticatedPage';

const ConsentCenterPage = lazy(() => import('pages/consent'));
const ErrorPage = lazy(() => import('pages/ErrorPage'));
const HomePage = lazy(() => import('pages/getting-started'));
const MarketingPage = lazy(() => import('pages/marketing'));
const SettingsPage = lazy(() => import('pages/settings'));
const BotPage = lazy(() => import('pages/bot'));
const BackgroundJobsPage = lazy(() => import('pages/jobs'));
const PolicyCenterPage = lazy(() => import('pages/policy-center'));

export default function App() {
    const isAuthenticated = useIsAuthenticated();
    const {
        accounts: [account],
        inProgress,
        instance
    } = useMsal();

    const handleSignInButtonClick = () => {
        if (!isAuthenticated && inProgress === InteractionStatus.None) {
            instance.loginRedirect(loginRequest);
        }
    };

    const handleSignUpButtonClick = () => {
        if (!isAuthenticated && inProgress === InteractionStatus.None) {
            instance.loginRedirect({ ...loginRequest, prompt: 'create' });
        }
    };

    return (
        <>
            <AuthenticatedTemplate>
                <BrowserRouter>
                    <Suspense fallback={null}>
                        <Routes>
                            <Route
                                element={<AppLayout />}
                                errorElement={<ErrorPage />}
                                path="/">
                                <Route element={<HomePage />}
                                    index />
                                <Route element={<HomePage />}
                                    path="/getting-started/*" />
                                <Route
                                    element={<SettingsPage />}
                                    path="/settings/:settingsSection"
                                />
                                {/* <Route
                                        element={<PolicyCenterPage />}
                                        path="/policy-center/*"
                                    /> */}
                                <Route
                                    element={<ConsentCenterPage />}
                                    path="/consent-center/*"
                                />
                                <Route
                                    element={<MarketingPage />}
                                    path="/marketing/*"
                                />
                                <Route
                                    element={<BackgroundJobsPage />}
                                    path="/background-jobs"
                                />
                                <Route
                                    element={<BotPage />}
                                    path="/bot/*"
                                />

                                <Route element={<ErrorPage />}
                                    path="*" />
                            </Route>
                        </Routes>
                    </Suspense>
                </BrowserRouter>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <UnauthenticatedPage onSignIn={handleSignInButtonClick} onSignUp={handleSignUpButtonClick} />
            </UnauthenticatedTemplate>
        </>
    );
}
