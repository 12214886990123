import { useEffect } from 'react';

export default function useForceNoMacroScrollbar () {
    useEffect(() => {
        const style = document.createElement('style');

        style.textContent = `
            html,
            body,
            #root {
                height: 100%;
                overflow-y: hidden;
            }
        `;

        document.head.appendChild(style);

        return () => {
            document.head.removeChild(style);
        };
    }, []);
}
