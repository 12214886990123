import { ConsentifyClient } from 'utils/httpClient';

export class EnvironmentsApi extends ConsentifyClient {
    getEnvironment = (id: string) => {
        return this.get<EnvironmentResponse>({
            apiPath: `environments/${id}`
        });
    };

    getCurrentEnvironment = () => {
        return this.get<EnvironmentResponse>({
            apiPath: `environments/me`
        });
    };

    getEnvironments = () => {
        return this.get<EnvironmentResponse[]>({
            apiPath: `environments`
        });
    };

    createEnvironment = (create: EnvironmentRequest) => {
        return this.post<EnvironmentResponse>({
            apiPath: `environments`,
            data: create
        });
    };

    updateEnvironment = ({ id, ...request } : { id: string } & EnvironmentRequest) => {
        return this.patch<EnvironmentResponse>({
            apiPath: `environments/${id}`,
            data: request
        });
    };

    deleteEnvironment = (id: string) => {
        return this.delete({
            apiPath: `environments/${id}`
        });
    };

    purgeEnvironment = (id: string) => {
        return this.delete({
            apiPath: `environments/${id}?hardDelete=true`
        });
    };
}
