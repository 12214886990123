import { Card, SimpleGrid, Text, Image, Group, Badge, Button } from "@mantine/core";
import image from 'images/getting-started.jpg';

export default function DashboardPage() {
    return (
        <>
            <h1>
                <Text
                    align="center"
                    gradient={{ from: 'indigo', to: 'purple', deg: 45 }}
                    variant="gradient"
                >
                    Welcome to Consentify Business Portal
                </Text>
            </h1>

            <SimpleGrid cols={3}>
                <Card padding="lg"
                    radius="md"
                    shadow="sm"
                    withBorder>
                    <Card.Section>
                        <Image alt=""
                            height={160}
                            src={image} />
                    </Card.Section>

                    <Group mb="xs"
                        mt="md"
                        position="apart">
                        <Text weight={500}>Consent Center</Text>
                        <Badge color="purple"
                            variant="light">
                            NEW!
                        </Badge>
                    </Group>

                    <Text color="dimmed"
                        size="sm">
                        Control how your consent data is connected to the Consentify Unified
                        Consent Platform.
                    </Text>

                    <Button color="blue"
                        fullWidth
                        mt="md"
                        radius="md"
                        variant="light">
                        Connect Consent Data
                    </Button>
                </Card>

                <Card padding="lg"
                    radius="md"
                    shadow="sm"
                    withBorder>
                    <Card.Section>
                        <Image alt=""
                            height={160}
                            src={image} />
                    </Card.Section>

                    <Group mb="xs"
                        mt="md"
                        position="apart">
                        <Text weight={500}>Policy Center</Text>
                        <Badge color="purple"
                            variant="light">
                            NEW!
                        </Badge>
                    </Group>

                    <Text color="dimmed"
                        size="sm">
                        Choose how your customer&apos;s consent data is governed, add, update or
                        remove policies and more.
                    </Text>

                    <Button color="blue"
                        fullWidth
                        mt="md"
                        radius="md"
                        variant="light">
                        Apply Policies
                    </Button>
                </Card>

                <Card padding="lg"
                    radius="md"
                    shadow="sm"
                    withBorder>
                    <Card.Section>
                        <Image alt=""
                            height={160}
                            src={image} />
                    </Card.Section>

                    <Group mb="xs"
                        mt="md"
                        position="apart">
                        <Text weight={500}>Preference Center</Text>
                        <Badge color="purple"
                            variant="light">
                            NEW!
                        </Badge>
                    </Group>

                    <Text color="dimmed"
                        size="sm">
                        Design and publish your preference centers for your customers to
                        make changes to their consent data.
                    </Text>

                    <Button color="blue"
                        fullWidth
                        mt="md"
                        radius="md"
                        variant="light">
                        Design Preference Centers
                    </Button>
                </Card>

                <Card padding="lg"
                    radius="md"
                    shadow="sm"
                    withBorder>
                    <Card.Section>
                        <Image alt=""
                            height={160}
                            src={image} />
                    </Card.Section>

                    <Group mb="xs"
                        mt="md"
                        position="apart">
                        <Text weight={500}>Settings</Text>
                        <Badge color="purple"
                            variant="light">
                            NEW!
                        </Badge>
                    </Group>

                    <Text color="dimmed"
                        size="sm">
                        Configure appearance, edit business profile, add or remove users and
                        more.
                    </Text>

                    <Button color="blue"
                        fullWidth
                        mt="md"
                        radius="md"
                        variant="light">
                        Go to Settings
                    </Button>
                </Card>
            </SimpleGrid>
        </>);
}