import styled from '@emotion/styled';
import { Anchor, Button, Container, Image, Stack, Text, Title } from '@mantine/core';

import useForceNoMacroScrollbar from 'hooks/useForceNoMacroScrollbar';

import checkMark from './checkmark.jpg';

export default function UnauthenticatedPage ({
    onSignIn: onSignIn,
    onSignUp: onSignUp
}: Props) {
    useForceNoMacroScrollbar();

    return (
        <StyledBackground
            align='center'
            bg="#0d182f"
            h="100vh"
        >
            <Container h="80vh"
                sx={{
                    position: 'relative'
                }}
            >
                <Stack
                    align='center'
                    pt="20vh"
                >
                    <StyledImage alt="Consentify, Unified Platform for Consent Management"
                        src={checkMark} />
                    <Title order={1}>Consentity Unified Consent Platform</Title>
                    <Title order={4}>To access the Unified Consent Platform, you need to have an account to sign in.</Title>
                    <Button onClick={onSignIn}
                        mt='md'
                        sx={{
                            position: 'relative',
                            zIndex: 2
                        }}>Sign in</Button>
                    <p>
                        Don't have a Consentify account?
                        <Anchor href="#" ml="md" onClick={onSignUp}>Sign up one for free</Anchor>
                    </p>
                </Stack>
            </Container>
        </StyledBackground>
    );
}

interface Props {
    onSignIn: () => void;
    onSignUp: () => void;
}

const StyledBackground = styled(Stack)`
    background-color: #0d182f;
    background-image: url(./checkmark.jpg);
    background-size: 320px auto;
    color: white;

    & > * {
        color: white !important;
    }
`;

const StyledImage = styled(Image)`
    background-image: url(./checkmark.jpg);
    width: 320px !important;
    z-index: 1;
`;
