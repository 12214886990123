import {
    Icon3dCubeSphere,
    IconFunction,
    IconHelp,
    IconHome2,
    IconMessage2,
    IconSettings
} from '@tabler/icons-react';
import React from 'react';
import { SiGooglemarketingplatform } from 'react-icons/si';

export interface MainLinkProps {
    color?: string;
    icon?: React.ReactNode;
    label: string;
    links?: MainLinkProps[];
    name?: string;
    path: string;
}

export const mainData: MainLinkProps[] = [
    {
        icon: <IconHome2 size={16} />,
        color: 'blue',
        label: 'Getting Started',
        path: '/getting-started',
        links: []
    },
    // {
    //     icon: <Icon3dCubeSphere size={16} />,
    //     color: 'violet',
    //     label: 'Policy Center',
    //     path: '/policy-center',
    //     links: [
    //         {
    //             label: 'Data Policies',
    //             path: '/policy-center/data-policies'
    //         },
    //         {
    //             label: 'Regulations',
    //             path: '/policy-center/regulations'
    //         }
    //     ]
    // },
    {
        icon: <IconFunction size={16} />,
        color: 'teal',
        label: 'Consent Center',
        path: '/consent-center',
        links: [
            {
                label: 'Contact Profiles',
                path: '/consent-center/contact-profiles'
            },
            {
                label: 'Contact Points',
                path: '/consent-center/contact-points'
            },
            {
                label: 'Data Use Purposes',
                path: '/consent-center/data-use-purposes'
            },
            {
                label: 'Subscriptions',
                path: '/consent-center/subscriptions'
            },
            {
                label: 'Consent Collection Forms',
                path: '/consent-center/collection-forms'
            }
        ]
    },
    {
        icon: <SiGooglemarketingplatform size={16} />,
        color: 'violet',
        label: 'Addons',
        path: '/',
        links: [
            // { label: 'Campaigns', path: '/marketing/campaigns' },
            // { label: 'Email Templates', path: '/marketing/email-templates' },
            {
                icon: <IconMessage2 size={16} />,
                color: 'yellow',
                label: 'Bot',
                path: '/bot'
            }
        ]
    }
];

export const additionalData: MainLinkProps[] = [
    {
        icon: <IconSettings size={16} />,
        color: 'gray',
        label: 'Settings',
        path: '/settings/general'
    },
    {
        icon: <IconHelp size={16} />,
        color: 'gray',
        label: 'Help & Support',
        path: '/help'
    }
];
