import { type NotificationProps } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { IconCheck, IconInfoCircle, IconX } from '@tabler/icons-react';

type ToastOptions = NotificationProps & Record<string, any>;

const toast = {
    error: (message: string, options?: ToastOptions) => {
        notifications.show({
            color: 'red',
            icon: <IconX size="1.1rem" />,
            message,
            title: 'Error',
            ...options
        });
    },
    success: (message: string, options?: ToastOptions) => {
        notifications.show({
            color: 'green',
            icon: <IconCheck size="1.1rem" />,
            message,
            title: 'Succcess',
            ...options
        });
    },
    info: (options: string | ToastOptions) => {
        if (!options) {
            throw new Error('Either message or options.message must be provided');
        }

        const message = typeof options === 'string'
            ? options
            : options.message;

        notifications.show({
            icon: <IconInfoCircle size="1.1rem" />,
            title: 'Info',
            color: 'blue',
            ...(typeof options === 'string'
                ? {}
                : options),
            message
        });
    },
    updateNotification: (options: ToastOptions & {
        id: string;
        message: string
    }) => {
        notifications.update(options);
    }
};

export default toast;
