import { type Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
    auth: {
        clientId: '17bfe66f-4d03-4110-96b8-e55153a23a24',
        authority: 'https://consentifycustomers.ciamlogin.com/',
        navigateToLoginRequestUrl: false,
        redirectUri:
            process.env.NODE_ENV === 'production'
                ? 'https://portal.consentify.io'
                : 'http://localhost:3000'
    },
    cache: {
        cacheLocation: 'sessionStorage',
        storeAuthStateInCookie: false
    }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
    scopes: ['https://graph.microsoft.com/user.read']
};
