import { NavLink, ThemeIcon } from '@mantine/core';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { additionalData, mainData, type MainLinkProps } from './navbarConfig';

export const AppNavMainLinks = () => {
    const { pathname } = useLocation();

    return (
        <>
            {mainData.map((link) => (
                <Menu
                    {...link}
                    key={link.path}
                >
                    {(!!link.links?.length) && link.links?.map((subLink) => (
                        <NavLink
                            {...subLink}
                            active={pathname.startsWith(subLink.path)}
                            component={Link}
                            key={subLink.label}
                            to={subLink.path}
                        />
                    ))}
                </Menu>
            ))}
        </>
    );
};

export const AppNavAdditionalLinks = () => (
    <>
        {additionalData.map((link) => (
            <NavLink
                {...link}
                component={Link}
                icon={(
                    <ThemeIcon color={link.color}
                        variant="light">
                        {link.icon}
                    </ThemeIcon>
                )}
                key={link.label}
                to={link.path}
            />
        ))}
    </>
);

const Menu: React.FC<MenuProps> = ({ ...menu }) => {
    const { pathname } = useLocation();
    const [isExpanded, setIsExpanded] = useState(pathname.startsWith(menu.path));

    return (
        <NavLink {...menu}
            component={Link}
            icon={(
                <ThemeIcon color={menu.color}
                    variant="light">
                    {menu.icon}
                </ThemeIcon>
            )}
            onClick={() => { setIsExpanded((isExpanded) => !isExpanded); }}
            opened={isExpanded}
            to={menu.path}
        />
    );
};

type MenuProps = MainLinkProps & { children?: React.ReactNode };
