import { useMsal } from '@azure/msal-react';
import { useLocalStorage } from '@mantine/hooks';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { EnvironmentsApi } from 'services/environments';

const ENVIRONMENT_QUERY_KEYS = ['environments'];

export function useEnvironmentsApi () : EnvironmentsApi {
    const {
        instance
    } = useMsal();
    const [environment] = useLocalStorage({ key: 'environment', getInitialValueInEffect: false });
    
    return useMemo(
        () => new EnvironmentsApi(instance, environment),
        [instance, environment]
    );
}

export function useGetEnvironment(id: string) {
    const api = useEnvironmentsApi();

    return useQuery({
        queryFn: () => api.getEnvironment(id),
        queryKey: [...ENVIRONMENT_QUERY_KEYS, id]
    });
}

export function useGetCurrentEnvironment() {
    const api = useEnvironmentsApi();

    return useQuery({
        queryFn: () => api.getCurrentEnvironment(),
        queryKey: [...ENVIRONMENT_QUERY_KEYS, 'current']
    });
}

export function useGetEnvironments() {
    const api = useEnvironmentsApi();

    return useQuery({
        queryFn: api.getEnvironments,
        queryKey: ENVIRONMENT_QUERY_KEYS
    });
}

export function useCreateEnvironment () {
    const api = useEnvironmentsApi();

    return useMutation({
        mutationFn: api.createEnvironment
    });
}

export function useUpdateEnvironment () {
    const api = useEnvironmentsApi();

    return useMutation({
        mutationFn: api.updateEnvironment
    });
}

export function useDeleteEnvironment() {
    const api = useEnvironmentsApi();

    return useMutation({
        mutationFn: api.deleteEnvironment
    });
}

export function usePurgeEnvironment() {
    const api = useEnvironmentsApi();

    return useMutation({
        mutationFn: api.purgeEnvironment
    });
}
